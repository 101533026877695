import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  image: {
    brandImage: "",
    nutrionImage: "",
    ingredientImage: "",
  },
  isLoading: false,
  step: 1,
  openEditImage: false,
  data: {
    dataStep1: {
      productName: "",
      productBrand: "",
    },
    dataStep2: {
      calculated_on: {
        amount: 0,
        unit: "g/ml",
      },
      calories: {
        amount: 0,
        unit: "kcal",
      },
      sugar: {
        amount: 0,
        unit: "g",
      },
      fiber: {
        amount: 0,
        unit: "g",
      },
      saturated_fat: {
        amount: 0,
        unit: "g",
      },
      sodium: {
        amount: 0,
        unit: "mg",
      },
      protein: {
        amount: 0,
        unit: "g",
      },
    },
    dataStep3: {
      additives: [],
    },
  },
};

const userScanSlice = createSlice({
  name: "userScan",
  initialState,
  reducers: {
    getImages: (state) => {
      const brandImage = sessionStorage.getItem("brandImage");
      const nutrionImage = sessionStorage.getItem("nutrionImage");
      const ingredientImage = sessionStorage.getItem("ingredientImage");
      state.image = {
        brandImage,
        nutrionImage,
        ingredientImage,
      };
    },
    setImage: (state, action) => {
      const { name, image } = action.payload;
      state.image[name] = image;
      sessionStorage.setItem(`${name}`, state.image[name]);
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    resetStep: (state) => {
      state.step = 1;
    },
    changeNextStep: (state) => {
      state.step = state.step + 1;
    },
    changePreviousStep: (state) => {
      state.step = state.step - 1;
    },
    resetImages: (state) => {
      state.image = {
        brandImage: "",
        nutrionImage: "",
        ingredientImage: "",
      };
      sessionStorage.setItem("brandImage", "");
      sessionStorage.setItem("nutrionImage", "");
      sessionStorage.setItem("ingredientImage", "");
    },
    resetImage: (state, action) => {
      const { name } = action.payload;
      state.image[name] = "";
      sessionStorage.setItem(`${name}`, state.image[name]);
    },
    changeOpenEditImage: (state, action) => {
      state.openEditImage = action.payload;
    },
    changeData: (state, action) => {
      state.data[action.payload.name] = action.payload.value;
    },
  },
});

export const {
  setImage,
  setLoading,
  resetStep,
  changeNextStep,
  changeOpenEditImage,
  getImages,
  changeData,
  resetImages
} = userScanSlice.actions;
export default userScanSlice.reducer;
