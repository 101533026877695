import { Box, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import CustomizedProgressBars from "./CircularCustom";

const CompleteStepWaitResult = () => {
  const { image, data } = useSelector((state) => state.userScan);
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        flexDirection: "column",
        gap: "1.5rem",        
      }}
    >
      <Typography
        sx={{
          fontSize: "24px",
          fontWeight: 500,
          lineHeight: "30px",
          color: "#3D3C3D",
        }}
      >
        Generating the result for
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1.25rem",
        }}
      >
        <img
          src={image.brandImage}
          alt="product font"
          width={"160px"}
          height={"157.35px"}
          style={{
            borderRadius: "12px",
          }}
        />
        <Typography
          sx={{
            fontSize: "18px",
            fontWeight: 500,
            lineHeight: "22.5px",
            color: "#38383D",
          }}
        >
          {data?.dataStep1.productName}
        </Typography>
        <Typography
          sx={{
            fontWeight: "400",
            lineHeight: "17.5px",
            color: "#7D8899",
            fontSize: "14px",
          }}
        >
          It only takes few seconds
        </Typography>
      </Box>
      <CustomizedProgressBars />
    </Box>
  );
};

export default CompleteStepWaitResult;
