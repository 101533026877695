import { Box, Button, IconButton, Typography } from "@mui/material";
import { ReactComponent as CropIcon } from "../assets/crop.svg";
import { ReactComponent as RorateIcon } from "../assets/rotate.svg";

import React, { useState } from "react";

const BottomRetake = ({ onClickRorate, handleClick, openCrop }) => {
  const [count, setCount] = useState(0);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: "1rem",
        width: "100%",
        borderTop: "1px solid #EBEBF0",
        position: "fixed",
        bottom: 0,
        left: 0,
        padding: "16px 24px 16px 24px",
        backgroundColor : "white"
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "1.5rem",
        }}
      >
        <IconButton
          sx={{
            gap: "8px",
          }}
          onClick={() => {
            openCrop(true);
          }}
        >
          <CropIcon />
          <Typography
            sx={{
              fontWeight: 700,
              color: "#32363D",
              fontSize: "14px",
            }}
          >
            Crop
          </Typography>
        </IconButton>
        <IconButton
          sx={{
            gap: "8px",
          }}
          onClick={() => {
            if (count === 0) {
              openCrop(true);
            }
            setCount((pre) => pre + 1);
            onClickRorate();
          }}
        >
          <RorateIcon />
          <Typography
            sx={{
              fontWeight: 700,
              color: "#32363D",
              fontSize: "14px",
            }}
          >
            Rotate
          </Typography>
        </IconButton>
      </Box>
      <Button
        sx={{
          backgroundColor: "#FF751E",
          color: "#FFFFFF",
          borderRadius: "16px",
          padding: "12px 16px 12px 16px",
          fontWeight: 700,
          fontSize: "14px",
          textTransform: "none",
          "&:hover": {
            backgroundColor: "transparent",
            border: "2px solid #FF751E",
            color: "#FF751E",
          },
        }}
        onClick={() => {
          handleClick();
          setCount(0)
          openCrop(false);
        }}
      >
        Done
      </Button>
    </Box>
  );
};

export default BottomRetake;
