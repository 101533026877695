import React, { useEffect } from "react";
import { ReactComponent as EditIcon } from "../assets/edit.svg";
import { Box, IconButton, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getNameStep } from "scenes/bitewise/userScan";
import { changeOpenEditImage, getImages } from "features/useScan/userScanSlice";

const ImageEdit = () => {
  const { step, image ,openEditImage } = useSelector((state) => state.userScan);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getImages());
  }, [dispatch,openEditImage]);
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "8px",
        flexDirection: "column",
      }}
    >
      <img
        style={{
          width: "86px",
          height: "110px",
          objectFit: "cover",
          borderRadius: "4px",
        }}
        src={image[getNameStep(step)]}
        alt="product"
      />
      <IconButton
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "4px",
        }}
        onClick={() => {
          dispatch(changeOpenEditImage(true));
        }}
      >
        <EditIcon />
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: "600",
            lineHeight: "17.5px",
            letterSpacing: "0.1px",
            color: "#32363D",
          }}
        >
          Edit
        </Typography>
      </IconButton>
    </Box>
  );
};

export default ImageEdit;
