import ImageCapture from "components/ImageCapture";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import NavBarDesktop from "../navbar/NavBarDesktop";
import { Helmet } from "react-helmet";
import { NotAvailable } from "../scanPage";
import NavBarUserScan from "components/NavbarUserScan";
import { useEffect, useState } from "react";
import ProductFont from "components/ProductFont";
import FormProductNameBrand from "components/FormProductNameBrand";
import ButtonSubmitContrinue from "components/ButtonSubmitContrinue";
import ImageCropRorate from "components/ImageCropRotate";
import { useDispatch, useSelector } from "react-redux";
import { getImages, setLoading } from "features/useScan/userScanSlice";
import axios from "axios";
import FormNutritionFact from "components/FormNutritionFact";
import FormIngredientAdditives from "components/FormIngredientAdditives";
import CompleteStepWaitResult from "components/CompleteStepWaitResult";
import ProductUnderReview from "components/ProductUnderReview";

export const getNameStep = (step) => {
  switch (step) {
    case 1:
      return "brandImage";
    case 2:
      return "nutrionImage";
    case 3:
      return "ingredientImage";
    default:
      return "";
  }
};

const UserScanPage = () => {
  const { step, isLoading, openEditImage, data } = useSelector(
    (state) => state.userScan
  );

  useEffect(() => {
    console.log("data", data);
  }, [data]);

  const { palette } = useTheme();
  const [imageDisplay, setImageDisplay] = useState();
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
  const backgrountWhite = palette.background.alt;
  const [readImageSuccessfull, setReadImageSuccessfull] = useState(false);
  const [dataStep1, setDataStep1] = useState();
  const [dataStep2, setDataStep2] = useState();
  const [dataStep3, setDataStep3] = useState();
  const [valueSubmit, setValueSubmit] = useState();

  useEffect(() => {
    if (step === 1) {
      setValueSubmit(dataStep1);
    } else if (step === 2) {
      setValueSubmit(dataStep2);
    } else {
      const filteredDataStep3 = {
        additives:
          dataStep3?.additives?.filter((additive) => additive !== "") || [],
      };
      setValueSubmit(filteredDataStep3);
    }
  }, [step, dataStep1, dataStep2, dataStep3]);

  const fetchAPIStep = async function (step) {
    setReadImageSuccessfull(false);
    console.log("readImageSuccessfull before", readImageSuccessfull);

    let endpoint;
    switch (step) {
      case 1:
        endpoint =
          "https://362ed87b-4a7a-4a88-a774-08c4ed2c6021.mock.pstmn.io/step1";
        break;
      case 2:
        endpoint =
          "https://362ed87b-4a7a-4a88-a774-08c4ed2c6021.mock.pstmn.io/step2";
        break;
      case 3:
        endpoint =
          "https://362ed87b-4a7a-4a88-a774-08c4ed2c6021.mock.pstmn.io/step3";
        break;
      default:
        throw new Error("Invalid step number");
    }

    try {
      const data = await axios.post(endpoint, imageDisplay);
      if (data) {
        setReadImageSuccessfull(true);
        console.log("readImageSuccessfull after", readImageSuccessfull);

        switch (step) {
          case 1:
            setDataStep1(data.data);
            break;
          case 2:
            setDataStep2(data.data);
            break;
          case 3:
            setDataStep3(data.data);
            break;
          default:
            throw new Error("Invalid step number");
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getImages());
  }, [dispatch]);

  useEffect(() => {
    const imageStep = sessionStorage.getItem(getNameStep(step));
    if (imageStep && step) {
      setImageDisplay(imageStep);
      fetchAPIStep(step);
    }
  }, [step, imageDisplay]);

  useEffect(() => {
    if (readImageSuccessfull) {
      dispatch(setLoading(false));
    }
  }, [readImageSuccessfull]);

  useEffect(() => {
    console.log("valueSubmit", valueSubmit);
  }, [valueSubmit]);

  useEffect(() => {
    console.log("readImageSuccessfull", readImageSuccessfull);
  }, [readImageSuccessfull]);

  const navBar = isNonMobileScreens ? (
    <NavBarDesktop />
  ) : (
    <NavBarUserScan step={step} />
  );

  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        position: "relative",
        overflow: "hidden",
      }}
    >
      {!openEditImage && (
        <>
          <Helmet title={`User-scan`}></Helmet>
          {navBar}
          {step < 4 && (
            <ProductFont
              isLoading={isLoading}
              readImageSuccessfull={readImageSuccessfull}
            />
          )}
          <Box
            width="100%"
            display={isNonMobileScreens ? "flex" : "block"}
            justifyContent="space-between"
            sx={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              position: "relative",
              height: "100%",
              overflow: "hidden",
              scrollbarWidth: "none",
              msOverflowStyle: "none",
              maxHeight: "100%",
            }}
          >
            <Box flexBasis={isNonMobileScreens ? "26%" : undefined}></Box>

            <Box
              flexBasis={isNonMobileScreens ? "30%" : undefined}
              width={isNonMobileScreens ? "30%" : undefined}
              minHeight={isNonMobileScreens ? "100vh" : undefined}
              backgroundColor={backgrountWhite}
              sx={{
                height: "100%",
              }}
            >
              {isNonMobileScreens ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    width: "100%",
                    bgcolor: "#EBEBF0",
                  }}
                >
                  {NotAvailable()}
                </Box>
              ) : (
                <Box
                  sx={{
                    display: " flex",
                    flexDirection: "column",
                    maxHeight: "100%",
                    overflow: readImageSuccessfull ? "scroll" : "hidden",
                  }}
                >
                  {step < 4 ? (
                    <>
                      {!readImageSuccessfull && (
                        <ImageCapture
                          getImage={(image) => {
                            setImageDisplay(image);
                          }}
                        />
                      )}
                    </>
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                        width: "100%",
                        backgroundColor: "white",
                      }}
                    >
                      <CompleteStepWaitResult />
                    </Box>
                  )}
                  {readImageSuccessfull && (
                    <>
                      {step === 1 && (
                        <FormProductNameBrand
                          data={dataStep1}
                          getDataStep1={(value) => setDataStep1(value)}
                        />
                      )}
                      {step === 2 && (
                        <FormNutritionFact
                          data={dataStep2}
                          getDataStep2={(value) => setDataStep2(value)}
                        />
                      )}
                      {step === 3 && (
                        <FormIngredientAdditives
                          data={dataStep3}
                          getDataStep3={(value) => setDataStep3(value)}
                        />
                      )}

                      {step < 4 && (
                        <ButtonSubmitContrinue
                          value={valueSubmit}
                          handleResetReadImage={() => {
                            setReadImageSuccessfull(false);
                          }}
                        />
                      )}
                    </>
                  )}
                </Box>
              )}
            </Box>
            {isNonMobileScreens && <Box flexBasis="26%"></Box>}
          </Box>
        </>
      )}

      {openEditImage && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            zIndex: 100,
            width: "100%",
            height: "100vh",
            overflowY: "hidden",
          }}
        >
          <ImageCropRorate
            setReadImage={() => setReadImageSuccessfull(false)}
          />
        </Box>
      )}
    </Box>
  );
};

export default UserScanPage;
