import { Typography } from "@mui/material";
import React from "react";

const HeaderGuidance = ({ content }) => {
  return (
    <Typography
      sx={{
        fontSize: "12px",
        fontWeight: 400,
        lineHeight: "15px",
        letterSpacing: "0.1em",
        textAlign: "left",
        color: "#7D8899",
        textTransform: "uppercase",
      }}
    >
      {content}
    </Typography>
  );
};

export default HeaderGuidance;
