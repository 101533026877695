import { Box, MenuItem, Select, Typography } from "@mui/material";
import React, { useState } from "react";
import styled from "styled-components";
import { ReactComponent as CheckIcon } from "../assets/check-orange.svg";

const StyledInputField = styled.input`
  border: 1px solid ${(props) => (props.errorMessage ? "#B3261E" : "#7d8899")};
  border-radius: 0 8px 8px 0;
  border-left: 0.5px;
  padding: 4px 12px;
  width: 90px;
  height: 40px;
  font-size: 16px;
  font-weight: 400;
  text-align: end;
  color: #32363d;
  &:focus-visible {
    border: 2px solid #3d3c3d;
    outline: none;
    svg {
      visibility: visible;
    }
  }
  &::placeholder {
    color: #7d8899;
  }
`;

const getListMenuItem = (name) => {
  switch (name) {
    case "calculated_on":
      return [
        {
          value: "g",
          label: "(g) Gram",
        },
        {
          value: "ml",
          label: "(ml) Milliliter",
        },
      ];
    case "calories":
      return [
        {
          value: "kcal",
          label: "(kcal) Kilocalorie",
        },
        {
          value: "kJ",
          label: "(kJ) kilojoule",
        },
      ];
    case "sodium":
      return [
        {
          value: "mg",
          label: "(mg) Milligram",
        },
        {
          value: "%",
          label: "(%) Percentage",
        },
      ];

    default:
      return [
        {
          value: "g",
          label: "(g) Gram",
        },
        {
          value: "%",
          label: "(%) Percentage",
        },
      ];
  }
};

const InputNutritionForm = ({
  name = "",
  label = "",
  unit = "",
  valueInput = "",
  handleChange = () => {},
  errorMessage,
}) => {
  const listMenuItem = getListMenuItem(name);

  const [selectedValue, setSelectedValue] = useState(unit);

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    handleChange(inputValue, unit);
  };

  const handleUnitChange = (event) => {
    const selectedUnit = event.target.value;
    setSelectedValue(selectedUnit); // Update the selected value
    handleChange(valueInput, selectedUnit);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "12px",
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          gap: "12px",
        }}
      >
        <Typography
          sx={{
            color: "#7D8899",
            lineHeight: "15px",
            letterSpacing: "1px",
            textTransform: "uppercase",
            fontSize: "12px",
            fontWeight: "400",
            width: "174px",
          }}
        >
          {label}
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            width: "100%",
            flex: 1,
          }}
        >
          <Select
            value={unit}
            onChange={handleUnitChange}
            displayEmpty
            renderValue={(selected) => {
              const selectedItem = listMenuItem.find(
                (item) => item.value === selected
              );
              return selectedItem ? selectedItem.value : "Select";
            }}
            placeholder="Select"
            errorMessage={errorMessage}
            sx={{
              width: "100%",
              height: "40px",
              borderRadius: "8px 0 0 8px",
              borderRight: "0.5px",
              borderColor: "#7d8899",
              color: "#7d8899",
            }}
            MenuProps={{
              sx: {
                "& ul": {
                  padding: 0,
                  marginTop: 0,
                  listStyleType: "none",
                  backgroundColor: "white",
                  borderRadius: "8px",
                  boxShadow: "0px 2px 8px 0px #00000033",
                },
              },
            }}
          >
            {listMenuItem &&
              listMenuItem.map((item, index) => (
                <MenuItem
                  value={item.value}
                  key={index}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: "8px",
                    width: "180px",
                    padding: "8px 16px",
                    backgroundColor:
                      selectedValue === item.value ? "#FFF1E8" : "white",
                    "&.Mui-selected": {
                      backgroundColor: "#FFF1E8",
                    },
                  }}
                >
                  {item.label}
                  {selectedValue === item.value && <CheckIcon />}{" "}
                </MenuItem>
              ))}
          </Select>

          <StyledInputField
            type="text"
            value={valueInput}
            onChange={handleInputChange}
            placeholder="input"
            errorMessage={errorMessage}
          />
        </Box>
      </Box>

      {errorMessage && (
        <Typography
          sx={{
            alignSelf: "flex-end",
            color: "#B3261E",
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "17.5px",
            letterSpacing: "0.5px",
            textAlign: "right",
            width: "247px",
          }}
        >
          Don’t leave empty <br />
          Input “-” if not mentioned
        </Typography>
      )}
    </Box>
  );
};

export default InputNutritionForm;
