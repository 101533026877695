import styled from "styled-components";
import { ReactComponent as CloseIcon } from "../assets/closeIconForm.svg";
import { Box } from "@mui/material";

const InputContainer = styled.div`
  position: relative;
  width: 100%;
`;

const ClearIcon = styled.div`
  position: absolute;
  width: 20px;
  height: 20px;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
`;

const StyledInputField = styled.input`
  border: 1px solid ${(props) => (props.error ? "#B3261E" : "#7D8899")};
  border-radius: 8px;
  padding: 4px 12px;
  padding-right: 32px;
  width: 100%;
  height: 48px;
  font-size: 16px;
  color: #32363d;
  &:focus-visible {
    border: 2px solid #3d3c3d;
    outline: none;
    svg {
      visibility: visible;
    }
  }
  &::placeholder {
    color: #7d8899;
  }
`;

const ErrorMessage = styled.div`
  color: #b3261e;
  font-size: 14px;
  margin-top: 4px;
`;

export const StyledInput = ({
  value,
  onChange,
  placeholder,
  errorMessage,
  ...props
}) => {
  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    onChange(inputValue);
  };

  const handleClear = () => {
    onChange("");
  };

  return (
    <>
      <InputContainer>
        <StyledInputField
          {...props}
          value={value}
          onChange={handleInputChange}
          placeholder={placeholder}
          error={errorMessage}
          minLength={3}
          maxLength={250}
        />
        <Box sx={{ visibility: value !== "" ? "visible" : "hidden" }}>
          <ClearIcon onClick={handleClear}>
            <CloseIcon />
          </ClearIcon>
        </Box>
      </InputContainer>
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </>
  );
};
