import {
  Box,
  Button,
  useMediaQuery,
  Typography,
  useTheme,
  IconButton,
} from "@mui/material";
import ProductDetailsProductWidget from "scenes/widgets/ProductDetailsProductWidget";
import RisksPositivesWidget from "scenes/widgets/RisksPositivesWidget";
import AlternativeProductsListWidget, {
  EmptyView,
} from "scenes/widgets/AlternativeProductsListWidget";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  useGetAlternativesListQuery,
  useGetHealthConditionListQuery,
  useGetProductDetailQuery,
  useGetSearchByBrandCountQuery,
  useGetSimilarsListQuery,
} from "../../../app/services/auth/authService";
import { useEffect, useState } from "react";
import GenericNavBar from "../navbar/GenericNavBar";
import { Helmet } from "react-helmet";
import FlexBetweenVertical from "components/FlexBetweenVertical";
import { map, isEmpty } from "lodash";
import DialogWidget from "scenes/widgets/DialogWidget";
import { useDispatch, useSelector } from "react-redux";
import { setWidget } from "features/bottomSheet/bottomSheetSlice";
import LoadingWidget from "scenes/widgets/LoadingWidget";
import ErrorWidget from "scenes/widgets/ErrorWidget";
import NavBarDesktop from "../navbar/NavBarDesktop";
import FlexBetween from "components/FlexBetween";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import FaqMenuGroupButton from "../navbar/FaqMenuGroupButton";
import { HOST_NAME } from "utils/constants";
import DisclaimerWidget from "scenes/widgets/DisclaimerWidget";
import { ReactComponent as GirlDontKnowNotRated } from "../../../assets/girl_dontknow_expression.svg";
import parse from "html-react-parser";
import ProductUnderReview from "components/ProductUnderReview";
import { debugLog, filterRiskItems } from "utils/commonutils";
import HealthConditionPDPWidget from "scenes/widgets/HealthConditionPDPWidget";

const BitewiseProductPage = () => {
  const { palette } = useTheme();
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
  const backgroundWhite = palette.background.alt;
  const backgroundProductDetail = palette.background.productDetail;
  const { t } = useTranslation();
  const { productId } = useParams();
  const [alternativeCount, setAlternativeCount] = useState(-1);
  const [brandProductsCount, setBrandProductsCount] = useState(-1);
  const [alternativeShow, setAlternativeShow] = useState();
  const [isFetchAlternatives, setIsFetchAlternatives] = useState(false);
  const [isFetchSimilars, setIsFetchSimilars] = useState(false);
  let healthData = null;
  const navigate = useNavigate();
  const location = useLocation();
  const textAppBarTitle = palette.textColor.appBarTitle;
  const servingSize = 100;
  const servingUnit = "g";

  const { state } = useLocation();
  const { searchQueryId } = state == null ? {} : state;
  debugLog("searchQueryId: " + searchQueryId);
  const { isChangeHealth } = useSelector((state) => state.bottomSheet);
  // console.log("ArsyProductId: " + productId);
  const {
    data,
    isLoading,
    error,
    refetch: refetchProductDetails,
  } = useGetProductDetailQuery({
    productId,
    searchQueryId,
  });

  useEffect(() => {
    refetchProductDetails();
  }, [isChangeHealth]);

  const {
    data: alternativesList,
    isLoading: isLoadingAlternatives,
    error: errorAlternatives,
  } = useGetAlternativesListQuery(
    { ids: productId },
    {
      skip: isFetchAlternatives === false,
    }
  );

  const {
    data: similarsList,
    isLoading: isLoadingSimilars,
    error: errorSimilars,
  } = useGetSimilarsListQuery(
    { ids: productId },
    {
      skip: isFetchSimilars === false,
    }
  );

  const {
    data: productBrandCount,
    isLoading: isLoadingBrandProductsCount,
    error: errorBrandProductsCount,
  } = useGetSearchByBrandCountQuery(
    {
      searchValue: data?.brand,
    },
    {
      skip:
        data?.brand === undefined || data?.brand === null || data?.brand === "",
    }
  );
  // useEffect(() => {
  //   console.log("similarsList: " + similarsList);
  //   console.log("isFetchSimilars", isFetchSimilars);
  // }, [similarsList, isFetchSimilars]);

  useEffect(() => {
    // console.log("insideuseeffect");
    // console.log("alternativesList: " + alternativesList);
    if (
      alternativesList &&
      alternativesList.length > 0 &&
      alternativesList[0].alternative_products &&
      data?.rating !== "GOOD"
    ) {
      setAlternativeCount(alternativesList[0].alternative_products.length);
    }
  }, [alternativesList]);

  useEffect(() => {
    if (
      similarsList &&
      similarsList.length > 0 &&
      similarsList[0].alternative_products &&
      data?.rating === "GOOD"
    ) {
      setAlternativeCount(similarsList[0].alternative_products.length);
    }
  }, [similarsList]);

  useEffect(() => {
    if (data && "NOT_RATED" != data.rating && data.rating != "GOOD") {
      setIsFetchAlternatives(true);
    }
    if (data && "NOT_RATED" != data.rating && "GOOD" == data.rating) {
      setIsFetchSimilars(true);
    }
  }, [data]);

  useEffect(() => {
    if (
      alternativesList &&
      alternativesList.length &&
      "NOT_RATED" != data.rating &&
      data.rating != "GOOD"
    ) {
      setAlternativeShow(alternativesList || []);
    }
    if (
      similarsList &&
      similarsList.length &&
      "NOT_RATED" != data.rating &&
      "GOOD" == data.rating
    ) {
      setAlternativeShow(similarsList || []);
    }
  }, [alternativesList, similarsList]);

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      section_of_website: "product",
    });
  }, []);

  function setPreProcessedHealthData(data) {
    if (data == null) return;
    // console.log("Arsy data before preprocess: ", healthData);
    healthData = preProcessData(data);
    debugLog("PDP after preProcessData: ", healthData);
  }

  function getShareData(data) {
    // Set share data
    if (data == null) return;
    return {
      type: "product",
      title: "Share",
      text: data.name,
      url: HOST_NAME + "/product/" + data.id,
    };
  }

  const { openDisclaimer } = useSelector(
    (state) => state.bottomSheet.openBottomSheet
  );

  const [payload, setPayload] = useState({
    name: "openHealthImpact",
    isOpen: false,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setWidget(payload));
  }, [dispatch, payload]);

  const handleClose = (openWidgetName) => {
    setPayload({
      name: openWidgetName,
      isOpen: false,
    });
  };

  useEffect(() => {
    console.log("productBrandCount", productBrandCount);
    // setBrandProductsCount()
  }, [productBrandCount]);

  const { data: healthConditions } = useGetHealthConditionListQuery();

  const navBar = isNonMobileScreens ? (
    <NavBarDesktop />
  ) : (
    <GenericNavBar
      appBarBackgroundColor={backgroundWhite}
      showShareIcon={true}
      shareData={getShareData(data)}
    />
  );
  const servingSizeAndServingUnit =
    data?.serving_size && data?.serving_unit
      ? "(" + getServingSizeText(data?.serving_size, data?.serving_unit) + ")"
      : "";

  const renderingPositivesNegatives = () => {
    const allergicIngredients = healthData.negatives.ingredients.filter(
      (ingredient) => ingredient.allergic === true
    );
    const additives = healthData.negatives.ingredients.filter(
      (ingredient) => ingredient.allergic === false
    );

    const dataNegative =
      ("NOT_RATED" === data.rating &&
        isEmpty(allergicIngredients) &&
        isEmpty(additives) &&
        isEmpty(healthData.negatives.nutrients)) ||
      ("NOT_RATED" === data.rating &&
        isNotRatedSpecialProduct(data.product_rate_enabled) &&
        isEmpty(allergicIngredients)) ? (
        <></>
      ) : (
        <RisksPositivesWidget
          isRiskView={true}
          name={t("productDetail.negatives_title")}
          inquantity={getServingSizeText(servingSize, "")}
          servingUnit={servingUnit}
          healthItems={{
            ingredients: allergicIngredients,
            additives: additives,
            nutrients: healthData.negatives.nutrients,
          }}
          translation={t}
          hideNutrients={
            "NOT_RATED" === data.rating &&
            isNotRatedSpecialProduct(data.product_rate_enabled)
          }
        />
      );

    const dataPositive = (!isEmpty(healthData.positives.ingredients) ||
      !isEmpty(healthData.positives.nutrients)) && (
      <RisksPositivesWidget
        isRiskView={false}
        name={t("productDetail.positives_title")}
        inquantity={getServingSizeText(servingSize, "")}
        searvingUnit={servingUnit}
        healthItems={healthData.positives}
        translation={t}
      />
    );

    // For good products show positives first
    if (
      "GOOD" != data.rating &&
      healthData.negatives &&
      (healthData.negatives.ingredients.length > 0 ||
        healthData.negatives.nutrients.length > 0)
    ) {
      return [dataNegative, dataPositive];
    } else {
      return [dataPositive, dataNegative];
    }
  };

  //  Desktop version (height alternative Box following height PDP view)

  const [heighPdpWapper, setHeighPdpWapper] = useState();
  const [heightAlternativeWapper, setHeightAlternativeWapper] = useState();
  const { isActiveAccordionPDP } = useSelector((state) => state.bottomSheet);
  const [times, setTimes] = useState(1);

  useEffect(() => {
    const heighPdpWapperElement = document.getElementById("pdp_wapper");
    const measureHeight = () => {
      if (heighPdpWapperElement && isNonMobileScreens) {
        const heighPdpWapper = heighPdpWapperElement.offsetHeight;
        setHeighPdpWapper(heighPdpWapper);
      }
    };
    measureHeight();

    // waiting alternative load
    if (times <= 2) {
      setTimeout(measureHeight, 1000);
    } else {
      measureHeight();
    }
    setTimes(times + 1);
  }, [data, isActiveAccordionPDP]);

  useEffect(() => {
    if (heighPdpWapper && isNonMobileScreens) {
      setHeightAlternativeWapper(heighPdpWapper);
    }
  }, [heighPdpWapper, data]);

  return (
    <Box>
      <Helmet
        title={
          data && data.name.length > 0 ? `${data.name} | Bitewise` : `Bitewise`
        }
      ></Helmet>
      {navBar}
      <Box>
        {setPreProcessedHealthData(data)}
        {/* {console.log("Arsyyy data: ", healthData)} */}
        <Box
          width="100%"
          display={isNonMobileScreens ? "flex" : "block"}
          gap="0.5rem"
          justifyContent="space-between"
        >
          <Box flexBasis={isNonMobileScreens ? "26%" : undefined} id="1"></Box>

          {isLoading ? (
            <Box
              flexBasis={isNonMobileScreens ? "30%" : undefined}
              width={isNonMobileScreens ? "30%" : undefined}
            >
              <LoadingWidget />
            </Box>
          ) : error ? (
            <Box
              flexBasis={isNonMobileScreens ? "30%" : undefined}
              width={isNonMobileScreens ? "30%" : undefined}
            >
              <Box
                sx={{
                  width: "100%",
                  height: "85vh",
                }}
              >
                <ErrorWidget error={error} />
              </Box>
            </Box>
          ) : (
            <Box
              flexBasis={isNonMobileScreens ? "30%" : undefined}
              width={isNonMobileScreens ? "30%" : undefined}
              backgroundColor={backgroundProductDetail}
              sx={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
                minHeight: isNonMobileScreens ? "100vh" : "100%",
              }}
              id="pdp_wapper"
            >
              {isNonMobileScreens && (
                <FlexBetween
                  gap="1rem"
                  sx={{
                    justifyContent: "flex-between",
                    background: "white",
                  }}
                >
                  <IconButton
                    onClick={() => {
                      // console.log("Arsy: " + window.history.length + " -  " + document.referrer + "  -  " + location.key);
                      if (
                        location.key === "default" ||
                        window.history.length <= 2
                      ) {
                        navigate(`/`);
                      } else {
                        navigate(-1);
                      }
                    }}
                  >
                    <ArrowBackIcon
                      style={{
                        color: "#3D3C3D",
                        width: "1.725rem",
                        height: "1.5rem",
                      }}
                    />
                  </IconButton>
                  <Typography
                    variant="h20"
                    color={textAppBarTitle}
                    fontWeight="400"
                    sx={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "-webkit-box",
                      WebkitLineClamp: "1",
                      WebkitBoxOrient: "vertical",
                    }}
                  >
                    <FaqMenuGroupButton
                      showShareIcon={true}
                      offDehazeIcon={isNonMobileScreens}
                      shareData={getShareData(data)}
                    />
                  </Typography>
                </FlexBetween>
              )}
              <Box
                padding="1rem 1.5rem 1rem 1.5rem"
                backgroundColor={backgroundWhite}
                display={"flex"}
                justifyContent={"center"}
              >
                <ProductDetailsProductWidget
                  productItem={data}
                  alternativeCount={alternativeCount}
                  brandProductsCount={productBrandCount || -1}
                  isNonMobileScreens={isNonMobileScreens}
                  isNotRatedSpecialProduct={isNotRatedSpecialProduct(
                    data.product_rate_enabled
                  )}
                />
              </Box>

              {/* {"NOT_RATED" === data.rating && isNotRatedSpecialProduct(data.product_rate_enabled)
              ?
              <EmptyViewNotRated palette={palette} />
              : */}
              <Box ml="0.75rem" mr="0.75rem" pb="2rem">
                <FlexBetweenVertical
                  gap="1.875rem"
                  p="1rem 0.75rem 0rem 0.75rem"
                  sx={{
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                  }}
                >
                  <HealthConditionPDPWidget
                    data={filterRiskItems(
                      healthData?.health_impacts?.negative,
                      healthConditions
                    )}
                  />
                  {renderingPositivesNegatives()}
                  {/* Show alternatives */}
                  {!isNonMobileScreens &&
                  alternativeShow &&
                  alternativeShow.length > 0 ? (
                    <AlternativeProductsListWidget
                      rating={data.rating}
                      name={
                        "GOOD" === data.rating
                          ? t("productDetail.similar_products_title")
                          : t("productDetail.healthieralternatives_title")
                      }
                      alternativeItems={alternativeShow[0].alternative_products}
                    />
                  ) : "GOOD" === data.rating ? (
                    !isNonMobileScreens && (
                      <EmptyView
                        name={
                          "GOOD" === data.rating
                            ? t("productDetail.similar_products_title")
                            : t("productDetail.healthieralternatives_title")
                        }
                        count={0}
                        palette={palette}
                      />
                    )
                  ) : (
                    <></>
                  )}
                </FlexBetweenVertical>
              </Box>
              {data?.needReview && <ProductUnderReview />}
            </Box>
          )}

          {isNonMobileScreens && (
            <Box
              flexBasis="26%"
              sx={{
                minHeight: "100vh",
                height: `${heightAlternativeWapper}px`,
              }}
              id="alternative_wapper"
            >
              {isLoading || error ? (
                <></>
              ) : (
                <Box
                  sx={{
                    width: "100%",
                    padding: "2rem 1.5rem 1.5rem 1.5rem",
                    backgroundColor:
                      alternativeCount > 0 || "GOOD" === data.rating
                        ? backgroundWhite
                        : "transparent",
                    height: "100%",
                    position: "sticky",
                    top: "0",
                    right: "0",
                  }}
                >
                  {isNonMobileScreens &&
                  alternativeShow &&
                  alternativeShow.length > 0 ? (
                    <AlternativeProductsListWidget
                      rating={data.rating}
                      name={
                        "GOOD" === data.rating
                          ? t("productDetail.similar_products_title")
                          : t("productDetail.healthieralternatives_title")
                      }
                      alternativeItems={alternativeShow[0].alternative_products}
                    />
                  ) : "GOOD" === data.rating ? (
                    <EmptyView
                      name={
                        "GOOD" === data.rating
                          ? t("productDetail.similar_products_title")
                          : t("productDetail.healthieralternatives_title")
                      }
                      count={0}
                      palette={palette}
                    />
                  ) : (
                    <></>
                  )}
                </Box>
              )}
            </Box>
          )}
        </Box>
      </Box>
      <DialogWidget
        open={openDisclaimer}
        onClose={() => handleClose("openDisclaimer")}
        dialogContent={
          <DisclaimerWidget onClose={() => handleClose("openDisclaimer")} />
        }
      />
    </Box>
  );
};

export function preProcessData(data) {
  let nutrientsMap = new Map();
  let ingredientsMap = new Map();

  let negativeNutrientsList = [];
  let positiveNutrientsList = [];
  let negativeIngredientList = [];
  let positiveIngredientList = [];

  data.nutrients &&
    map(data.nutrients, (item, index) => {
      nutrientsMap[item.id] = item;
      if ("POSITIVE" == item.type) {
        positiveNutrientsList.push(item);
      } else {
        negativeNutrientsList.push(item);
      }
    });

  data.ingredients &&
    map(data.ingredients, (item, index) => {
      ingredientsMap[item.id] = item;
      if ("POSITIVE" == item.type) {
        positiveIngredientList.push(item);
      } else if ("NEGATIVE" == item.type) {
        // HANDLE ON FRONTEND FOR NOW
        negativeIngredientList.push(item);
      }
    });

  let negativeHealthImpacts = [];
  let positiveHealthImpacts = [];
  // console.log("Arsy test abcd: ", ingredientsMap);
  // console.log("Arsy test abcd12: ", data.health_risks);

  data.health_risks &&
    map(data.health_risks, (risk_item, index) => {
      const nutrients = map(risk_item.nutrient_ids, (item, index) => {
        return {
          name: nutrientsMap[item].name,
          rating: nutrientsMap[item].rate_code,
          type: nutrientsMap[item].type,
          tag_name: nutrientsMap[item].tag_name,
        };
      });
      // console.log("Arsy test abcd2: ", risk_item);

      const ingredients = map(risk_item.ingredient_ids, (item, index) => {
        return {
          name: ingredientsMap[item].name,
          rating: ingredientsMap[item].allergic
            ? "ALLERGIC"
            : ingredientsMap[item].rate_code, // Handle allergic for now
          code: ingredientsMap[item].code,
          usage: ingredientsMap[item].usage,
        };
      });

      const riskitem = {
        risk_item: risk_item,
        nutrients: nutrients,
        ingredients: ingredients,
        tag_name: "",
      };
      // console.log("Arsy arsy nutrients: ", nutrients);
      // console.log("Arsy arsy ingredients: ", ingredients);
      // console.log("Arsy arsy riskitem: ", riskitem);

      if ("POSITIVE" == risk_item.type) {
        positiveHealthImpacts.push(riskitem);
      } else if ("NEGATIVE" == risk_item.type) {
        negativeHealthImpacts.push(riskitem);
      }
    });

  data.health_conditions &&
    map(data.health_conditions, (risk_item, index) => {
      const nutrients = map(risk_item.nutrient_ids, (item, index) => {
        return {
          name: nutrientsMap[item].name,
          rating: nutrientsMap[item].rate_code,
          type: nutrientsMap[item].type,
          tag_name: nutrientsMap[item].tag_name,
        };
      });
      // console.log("Arsy test abcd3: ", risk_item);

      const ingredients = map(risk_item.ingredient_ids, (item, index) => {
        return {
          name: ingredientsMap[item].name,
          rating: ingredientsMap[item].allergic
            ? "ALLERGIC"
            : ingredientsMap[item].rate_code, // Handle allergic for now
          code: ingredientsMap[item].code,
          usage: ingredientsMap[item].usage,
        };
      });

      const riskitem = {
        risk_item: risk_item,
        nutrients: nutrients,
        ingredients: ingredients,
        tag_name: "",
      };
      // console.log("Arsy arsy nutrients: ", nutrients);
      // console.log("Arsy arsy ingredients: ", ingredients);
      // console.log("Arsy arsy riskitem: ", riskitem);
      negativeHealthImpacts.push(riskitem);
    });
  // console.log("Arsy Health Items: " + healthResults);

  if (data.rating === "NOT_RATED") {
    return {
      negatives: {
        ingredients: negativeIngredientList,
        nutrients: [],
      },
      positives: {
        ingredients: [],
        nutrients: [],
      },
      health_impacts: {
        negative: negativeHealthImpacts.filter((pro) => !pro.nutrients.length),
        positive: positiveHealthImpacts,
      },
    };
  }

  const result = {
    negatives: {
      ingredients: negativeIngredientList,
      nutrients: negativeNutrientsList,
    },
    positives: {
      ingredients: positiveIngredientList,
      nutrients: positiveNutrientsList,
    },
    health_impacts: {
      negative: negativeHealthImpacts,
      positive: positiveHealthImpacts,
    },
  };

  // console.log("Arsy result: ", result);
  return result;
}

function EmptyViewHealthImpacts({ t, palette }) {
  return (
    <Box
      width="100%"
      gap="0.75rem"
      mt="0.75rem"
      pt="0.25rem"
      pb="0.25rem"
      display="flex"
      flexDirection="row"
      justifyContent="center"
      sx={{
        border: 1.5,
        borderColor: palette.borderColor.grey,
        borderRadius: "0.5rem",
        borderStyle: "dashed",
      }}
    >
      <Typography
        variant="h14"
        color={palette.textColor.mainHeading}
        fontWeight="600"
      >
        {t("productDetail.healthieralternatives_empty")}
      </Typography>
    </Box>
  );
}

function getServingSizeText(serving_size, serving_unit) {
  const ss =
    Math.ceil(serving_size) === Math.floor(serving_size)
      ? Math.floor(serving_size)
      : serving_size;
  const su = serving_unit ? serving_unit : "";
  return ss + su;
}

// function EmptyViewNotRated({ palette }) {
//   const navigate = useNavigate();
//   const { t } = useTranslation();
//   return (
//     <FlexBetweenVertical
//       height="80vh"
//       gap="1rem"
//       mt="-1.875rem"
//       ml="5rem"
//       mr="5rem"
//       sx={{ justifyContent: "center" }}
//     >
//       <GirlDontKnowNotRated />
//       <Typography
//         align="center"
//         variant="h20"
//         color={palette.textColor.mainHeading}
//         fontWeight="600"
//       >
//         {t("productDetail.not_rated_product_title")}
//       </Typography>
//       <FlexBetweenVertical
//         gap="0.5rem"
//         sx={{ justifyContent: "flex-start", alignItems: "flex-start" }}
//       >
//         <Typography
//           variant="h14"
//           color={palette.textColor.greyLight}
//           fontWeight="400"
//         >
//           {parse(t("productDetail.not_rated_product_subtitle_1"))}
//         </Typography>
//         <Typography
//           variant="h14"
//           color={palette.textColor.greyLight}
//           fontWeight="400"
//         >
//           {parse(t("productDetail.not_rated_product_subtitle_2"))}
//         </Typography>
//       </FlexBetweenVertical>
//     </FlexBetweenVertical>
//   );
// }

export function isNotRatedSpecialProduct(product_rate_enabled) {
  if (product_rate_enabled == null) return false;
  return !product_rate_enabled; // reverse the value if product_rate_enabled enabled then false
}

export default BitewiseProductPage;
