import { Box, Typography } from "@mui/material";

export default function LoadingSteps({ totalSteps, currentStep }) {
  const getStepColor = (stepNumber) => {
    return currentStep >= stepNumber ? "#FF751E" : "#E9E4E0";
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "end",
        alignItems: "center",
        gap: "16px",
        width: "100%",
        height: "15px",
      }}
    >
      <Box sx={{ display: "flex", gap: "4px" }}>
        {[...Array(totalSteps)].map((_, index) => (
          <Box
            key={index}
            sx={{
              width: "29.5px",
              height: "4px",
              backgroundColor: getStepColor(index + 1),
              borderRadius:
                index === 0
                  ? "8px 0 0 8px"
                  : index === totalSteps - 1
                  ? "0 8px 8px 0"
                  : 0,
            }}
          ></Box>
        ))}
      </Box>

      <Typography
        sx={{
          color: "#7D8899",
          fontSize: "0.75rem",
          fontWeight: 400,
          lineHeight: 1,
          letterSpacing: "0.01em",
          textAlign: "center",
        }}
      >
        {`STEP ${currentStep}`}
      </Typography>
    </Box>
  );
}
