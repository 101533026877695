import React, { useEffect, useState } from "react";
import InputNutritionForm from "./InputNutritionForm";
import { Box } from "@mui/material";

const FormNutritionFact = ({ data, getDataStep2 }) => {
  const [dataStep2, setDataStep2] = useState(data);

  const setFieldValueAndUnit = (fieldName, value, unit) => {
    setDataStep2((prevDataStep2) => ({
      ...prevDataStep2,
      [fieldName]: {
        amount: value,
        unit: unit,
      },
    }));
  };

  useEffect(() => {
    getDataStep2(dataStep2);
  }, [dataStep2]);

  return (
    <Box
      sx={{
        height: "100%",
      }}
    >
      <form
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          padding: "16px 24px 24px 24px",
          backgroundColor: "white",
        }}
      >
        <InputNutritionForm
          name="calculated_on"
          label="calculated on"
          unit={dataStep2?.calculated_on?.unit}
          handleChange={(value, unit) =>
            setFieldValueAndUnit("calculated_on", value, unit)
          }
          valueInput={dataStep2?.calculated_on?.amount || ""}
        />
        <InputNutritionForm
          name="calories"
          label="Calories"
          unit={dataStep2?.calories?.unit}
          handleChange={(value, unit) =>
            setFieldValueAndUnit("calories", value, unit)
          }
          valueInput={dataStep2?.calories?.amount || ""}
        />
        <InputNutritionForm
          name="sugar"
          label="sugar"
          unit={dataStep2?.sugar?.unit}
          handleChange={(value, unit) =>
            setFieldValueAndUnit("sugar", value, unit)
          }
          valueInput={dataStep2?.sugar?.amount || ""}
        />
        <InputNutritionForm
          name="fiber"
          label="fiber"
          unit={dataStep2?.fiber?.unit}
          handleChange={(value, unit) =>
            setFieldValueAndUnit("fiber", value, unit)
          }
          valueInput={dataStep2?.fiber?.amount || ""}
        />
        <InputNutritionForm
          name="saturated_fat"
          label="Saturated fat"
          unit={dataStep2?.saturated_fat?.unit}
          handleChange={(value, unit) =>
            setFieldValueAndUnit("saturated_fat", value, unit)
          }
          valueInput={dataStep2?.saturated_fat?.amount || ""}
        />
        <InputNutritionForm
          name="sodium"
          label="sodium"
          unit={dataStep2?.sodium?.unit}
          handleChange={(value, unit) =>
            setFieldValueAndUnit("sodium", value, unit)
          }
          valueInput={dataStep2?.sodium?.amount || ""}
        />
        <InputNutritionForm
          name="protein"
          label="protein"
          unit={dataStep2?.protein?.unit}
          handleChange={(value, unit) =>
            setFieldValueAndUnit("protein", value, unit)
          }
          valueInput={dataStep2?.protein?.amount || ""}
        />
      </form>
      <Box
        sx={{
          height: "115px",
        }}
      ></Box>
    </Box>
  );
};

export default FormNutritionFact;
