import { Box, Typography } from "@mui/material";
import React from "react";
import { ReactComponent as ExampleIcon } from "../assets/example.svg";

const ExampleProduct = ({ ExampleImage }) => {
  return (
    <Box
      sx={{
        borderRadius: "4px",
        position: "relative",
      }}
    >
      <img src={ExampleImage} alt="product" />
      <Box
        sx={{
          display: "flex",
          padding: "0px 6px 0px 0px",
          justifyContent: "center",
          alignItems: "center",
          bgcolor: "#FFFFFF",
          gap: "2px",
          borderRadius: "21px",
          boxShadow: " 0px 2px 8px 0px #00000033",
          width: "67px",
          height: "16px",
          position: "absolute",
          bottom: "10px",
          left: "3px",
        }}
      >
        <ExampleIcon />
        <Typography
          sx={{
            fontWeight: "600",
            color: "#00AB56",
            fontSize: "10px",
          }}
        >
          example
        </Typography>
      </Box>
    </Box>
  );
};

export default ExampleProduct;
