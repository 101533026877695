import { changeData, changeNextStep } from "features/useScan/userScanSlice";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDataUpdate } from "./ButtonSubmitContrinue";
import FlexBetween from "./FlexBetween";
import { Typography } from "@mui/material";

const SkipStep = ({ handleResetReadImage, isCaptureButton = false }) => {
  const { step } = useSelector((state) => state.userScan);
  const dispatch = useDispatch();

  const handleNextStep = () => {
    dispatch(changeData(getDataUpdate(step, {})));
    dispatch(changeNextStep());
    handleResetReadImage();
  };
  return (
    <>
      {step > 1 && (
        <FlexBetween
          sx={{
            gap: "4px",
          }}
        >
          <Typography
            sx={{
              color: isCaptureButton ? "white" : "#7D8899",
              fontSize: "14px",
              fontWeight: 400,
              lineHeight: 1,
            }}
          >
            {step === 2
              ? "Nutrition fact is not mentioned. "
              : "Ingredient list is not mentioned. "}
          </Typography>
          <Typography
            sx={{
              color: "#FF751E",
              cursor: "pointer",
              fontSize: "14px",
              fontWeight: 400,
              lineHeight: 1,
            }}
            onClick={handleNextStep}
          >
            Skip
          </Typography>
        </FlexBetween>
      )}
    </>
  );
};

export default SkipStep;
