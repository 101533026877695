import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import LabelInput from "./LabelInput";
import { StyledInput } from "./InputStyle";

const FormProductNameBrand = ({ data, getDataStep1 }) => {
  const [productName, setProductName] = useState(data?.productName || "");
  const [brand, setBrand] = useState(data?.productBrand || "");
  const [productNameError, setProductNameError] = useState("");
  const [brandError, setBrandError] = useState("");

  const handleProductNameChange = (inputValue) => {
    const sanitizedValue = sanitizeInput(inputValue, "productName");
    setProductName(sanitizedValue);
    setProductNameError(validateInput(sanitizedValue));
  };

  const handleBrandChange = (inputValue) => {
    const sanitizedValue = sanitizeInput(inputValue);
    setBrand(sanitizedValue);
    setBrandError(validateInput(sanitizedValue));
  };

  useEffect(() => {
    getDataStep1({
      productName: productName,
      productBrand: brand,
    });
  }, [productName, brand]);

  const sanitizeInput = (input, name) => {
    let sanitizedValue = input;
    if (name === "productName") {
      sanitizedValue = sanitizedValue.replace(/[^a-zA-Z\s'()]/g, "");
    } else if (name === "productBrand") {
      sanitizedValue = sanitizedValue.replace(/[^a-zA-Z\s']/g, "");
    }
    sanitizedValue = sanitizedValue.replace(/\b\w/g, char => char.toUpperCase());

    return sanitizedValue;
  };

  const validateInput = (input) => {
    if (!input) {
      return "Field cannot be empty.";
    }
    if (input.length < 3) {
      return "Name must be at least 3 characters long.";
    }
    return "";
  };

  return (
    <Box
      sx={{
        padding: "16px 24px 24px 24px",
      }}
    >
      <form
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: "8px",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <LabelInput name={"Product Name"} />
          <StyledInput
            type="text"
            value={productName}
            onChange={handleProductNameChange}
            errorMessage={productNameError}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: "8px",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <LabelInput name={"Brand"} />
          <StyledInput
            type="text"
            value={brand}
            onChange={handleBrandChange}
            errorMessage={brandError}
          />
        </Box>
      </form>
    </Box>
  );
};

export default FormProductNameBrand;
