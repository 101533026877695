import { Box, Typography } from "@mui/material";
import React from "react";
import CustomizedProgressBars from "./CircularCustom";

const ReadingImage = () => {
  return (
    <Box
      sx={{
        padding: "4px 24px 24px 24px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start",
        gap: "24px",
        backgroundColor: "white",
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: "6px",
          flexDirection: "column",
        }}
      >
        <Typography
          variant="h3"
          sx={{
            fontWeight: "500",
            lineHeight: "30px",
          }}
        >
          Reading the image...
        </Typography>
        <Typography
          sx={{
            fontWeight: "400",
            lineHeight: "17.5px",
            color: "#7D8899",
            fontSize: "14px",
          }}
        >
          It only takes few seconds
        </Typography>
        <CustomizedProgressBars />
      </Box>
    </Box>
  );
};

export default ReadingImage;
