import React, { useCallback, useRef, useState, useEffect } from "react";
import { ReactComponent as CaptureButton } from "../assets/capture.svg";
import { Box, Button, Dialog } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "features/useScan/userScanSlice";
import { getNameStep } from "scenes/bitewise/userScan";
import ErrorAccessWidget from "scenes/widgets/ErrorAccessWidget";
import { Transition } from "scenes/bitewise/scanPage";
import SkipStep from "./SkipStep";
import { Camera } from "react-camera-pro";

const ImageCapture = ({ getImage }) => {
  const { step } = useSelector((state) => state.userScan);
  const [picture, setPicture] = useState("");
  const [cameraAllowed, setCameraAllowed] = useState(false);
  const [loadingPermission, setLoadingPermission] = useState(true); // For handling the initial loading state
  const cameraRef = useRef(null);
  const dispatch = useDispatch();
  const [showErrorAccess, setShowErrorAccess] = useState(false);

  useEffect(() => {
    const storedPicture = sessionStorage.getItem(getNameStep(step));
    if (storedPicture) {
      setPicture(storedPicture);
      getImage(storedPicture);
    }
  }, [step, getImage]);

  const capture = () => {
    const pictureSrc = cameraRef.current.takePhoto();
    setPicture(pictureSrc);
    sessionStorage.setItem(getNameStep(step), pictureSrc);
    getImage(pictureSrc);
    dispatch(setLoading(true));
  };

  const requestCameraPermission = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      setCameraAllowed(true);
      stream.getTracks().forEach((track) => track.stop());
    } catch (error) {
      console.error("Error accessing camera:", error);
      setCameraAllowed(false);
      setShowErrorAccess(true);
    } finally {
      setLoadingPermission(false); // Stop loading after permission is checked
    }
  };

  useEffect(() => {
    requestCameraPermission();
  }, []);

  if (loadingPermission) {
    return <></>;
  }

  return (
    <Box style={{ flex: 1, height: "100%" }}>
      {picture === "" ? (
        <Box
          sx={{
            height: "100%",
          }}
        >
          {cameraAllowed ? (
            <Camera
              ref={cameraRef}
              facingMode="environment"
              aspectRatio={2 / 3}
              errorMessages={{
                noCameraAccessible: "",
              }}
            />
          ) : (
            <div>Camera access denied</div>
          )}
          <Box
            sx={{
              position: "fixed",
              bottom: "20px",
              left: "50%",
              transform: "translateX(-50%)",
              zIndex: 11,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            {cameraAllowed && picture === "" && (
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  capture();
                }}
                sx={{
                  padding: 0,
                }}
              >
                <CaptureButton />
              </Button>
            )}
            <SkipStep handleResetReadImage={() => {}} isCaptureButton={true} />
          </Box>
        </Box>
      ) : (
        <img
          src={picture}
          alt="Captured"
          style={{
            height: "100%",
            objectFit: "cover",
            width: "100%",
          }}
        />
      )}
      {showErrorAccess && (
        <Dialog
          open={showErrorAccess}
          onClose={() => {
            setShowErrorAccess(false);
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          TransitionComponent={Transition}
          fullWidth
          sx={{
            "& .MuiPaper-root": {
              position: "absolute",
              bottom: "0rem",
              borderRadius: "1rem",
            },
          }}
        >
          <ErrorAccessWidget
            onClose={() => {
              setShowErrorAccess(false);
            }}
          />
        </Dialog>
      )}
    </Box>
  );
};

export default ImageCapture;
