import { Box } from "@mui/material";
import ReadingImage from "./ReadingImage";
import ExampleProduct from "./ExampleProduct";
import ProductFontCard from "./ProductFontCard";
import ImageEdit from "./ImageEdit";
import { useSelector } from "react-redux";
import ExampleImageStep1 from "../assets/exampleStep1.png";
import ExampleImageStep2 from "../assets/exampleStep2.png";
import ExampleImageStep3 from "../assets/exampleStep3.png";
import { useEffect, useState } from "react";

const getDetailInformationStep = (step) => {
  switch (step) {
    case 1:
      return {
        title: "Product front",
        description:
          "Please make sure the product Name and Brand are covered in the image.",
        desSuccess: "Please review and correct",
        imageExample: ExampleImageStep1,
        extraInf: "",
      };
    case 2:
      return {
        title: "Nutrition fact",
        description:
          "Nutrition fact table is usually in the back. Please make sure all the content in the table is covered in the image.",
        desSuccess:
          "Help us ensure the quantity the nutrition is calculated on (usually per 100g or serving) and the corresponding nutrient values are accurate.",
        imageExample: ExampleImageStep2,
        extraInf: {
          example: "Can’t find Nutrition Fact",
          review: "Need more guidance",
        },
      };
    case 3:
      return {
        title: "Ingredient list – Additives",
        description:
          "Please provide us the image that includes all ingredient in the product.",
        desSuccess:
          "Please write down exactly how the additives are mentioned on the package.",
        imageExample: ExampleImageStep3,
        extraInf: {
          example: "Can’t find Ingredient list",
          review: "What is additive",
        },
      };
    default:
      return "";
  }
};

const ProductFont = ({
  isLoading = false,
  readImageSuccessfull = false,
  title = "",
}) => {
  const { step } = useSelector((state) => state.userScan);
  const [information, setInformation] = useState();

  useEffect(() => {
    setInformation(getDetailInformationStep(step));
  }, [step]);
  if (isLoading) {
    return <ReadingImage />;
  }
  return (
    <Box
      sx={{
        padding: "4px 24px 24px 24px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start",
        gap: "24px",
        paddingBottom: readImageSuccessfull ? 0 : "24px",
        backgroundColor: "white",
      }}
    >
      <ProductFontCard
        title={information?.title}
        description={
          readImageSuccessfull
            ? information?.desSuccess
            : information?.description
        }
        extraInf={information?.extraInf}
        readImageSuccessfull={readImageSuccessfull}
      />
      {!readImageSuccessfull && (
        <ExampleProduct ExampleImage={information?.imageExample} />
      )}
      {readImageSuccessfull && <ImageEdit />}
    </Box>
  );
};

export default ProductFont;
