import { Box, Typography, useTheme } from "@mui/material";
import React from "react";
import IngredientImage from "./../assets/ingredientGuidance.png";
import FlexBetweenVertical from "./FlexBetweenVertical";
import FlexBetween from "./FlexBetween";
import HeaderBottomSheet from "scenes/widgets/HeaderBottomSheet";
import HeaderGuidance from "./HeaderGuidance";

const AdditiveGuidance = ({ onClose }) => {
  const { palette } = useTheme();
  const backgrountWhite = palette.background.alt;

  return (
    <Box height="100%" backgroundColor={backgrountWhite}>
      <HeaderBottomSheet
        name={"Ingredient list guidance"}
        icon={""}
        close={onClose}
      />
      <FlexBetweenVertical
        sx={{
          gap: "30px",
          justifyContent: "flex-start",
          alignItems: "flex-start",
        }}
      >
        <FlexBetweenVertical
          sx={{
            gap: "12px",
            justifyContent: "flex-start",
            alignItems: "flex-start",
          }}
        >
          <HeaderGuidance content={"Where can find"} />
          <Typography fontSize={"14px"} color={"#32363D"}>
            Nutrition fact table is usually in the back or on the side of the
            package.
          </Typography>

          <img
            src={IngredientImage}
            alt="ingredient guidance"
            height={184}
            width={"100%"}
          />
          <Typography fontSize={"14px"} color={"#32363D"}>
            If you cannot find ...
          </Typography>
        </FlexBetweenVertical>
        <FlexBetweenVertical
          sx={{
            gap: "12px",
            justifyContent: "flex-start",
            alignItems: "flex-start",
          }}
        >
          <HeaderGuidance content={"additive"} />
          <Typography fontSize={"14px"} color={"#32363D"}>
            brief explain
          </Typography>
          <FlexBetween
            sx={{
              width: "100%",
              gap: "12px",
              flex: "1 1",
              alignItems: "center",
            }}
          ></FlexBetween>
          <Typography fontSize={"14px"} color={"#32363D"}>
            If ... then ...
          </Typography>
          <Typography fontSize={"14px"} color={"#32363D"}>
            If ... then ...
          </Typography>
          <Typography fontSize={"14px"} color={"#32363D"}>
            If ... then ...
          </Typography>
        </FlexBetweenVertical>
      </FlexBetweenVertical>
    </Box>
  );
};

export default AdditiveGuidance;
