import React, { useCallback, useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { StyledInput } from "./InputStyle";
import HeaderGuidance from "./HeaderGuidance";
import FlexBetweenVertical from "./FlexBetweenVertical";
import { ReactComponent as PlusIcon } from "../assets/plusIcon.svg";

const FormIngredientAdditives = ({ data, getDataStep3 }) => {
  const [additives, setAdditives] = useState(data.additives || []);

  useEffect(() => {
    setAdditives(data.additives || []);
  }, [data]);

  const handleAddAdditive = () => {
    setAdditives([...additives, ""]);
  };

  const handleAdditiveChange = (index, value) => {
    const newAdditives = [...additives];
    newAdditives[index] = value;
    setAdditives(newAdditives);
  };

  useEffect(() => {
    getDataStep3({
      additives: additives,
    });
  }, [additives]);

  const isAnyInputEmpty = useCallback(() => {
    return additives?.some((additive) => additive.trim() === "") || false;
  }, [additives]);
  return (
    <FlexBetweenVertical
      justifyContent={"space-between"}
      sx={{
        padding: "16px 24px 24px 24px",
      }}
    >
      <FlexBetweenVertical
        sx={{
          justifyContent: "flex-start",
          alignItems: "flex-start",
          gap: "1rem",
          width: "100%",
        }}
      >
        <HeaderGuidance content={`${additives.length} Additives`} />
        <form
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            width: "100%",
          }}
        >
          {additives.map((additive, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                gap: "8px",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <StyledInput
                type="text"
                value={additive}
                onChange={(value) => handleAdditiveChange(index, value)}
              />
            </Box>
          ))}
        </form>
        <Button
          onClick={handleAddAdditive}
          sx={{
            cursor: "pointer",
            display: "flex",
            gap: "4px",
            alignItems: "center",
            justifyContent: "center",
            padding: 0,
          }}
          disabled={isAnyInputEmpty()}
        >
          <PlusIcon
            style={{
              color: isAnyInputEmpty() ? "#d9d9d9" : "#32363D",
            }}
          />

          <Typography
            sx={{
              fontWeight: "700",
              fontSize: "14px",
              color: isAnyInputEmpty() ? "#d9d9d9" : "#32363D",
              letterSpacing: "0.1px",
              lineHeight: "17.5px",
              textAlign: "center",
            }}
          >
            {" "}
            Add additive
          </Typography>
        </Button>
      </FlexBetweenVertical>
      <Box height={113}></Box>
    </FlexBetweenVertical>
  );
};

export default FormIngredientAdditives;
