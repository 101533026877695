import { Box, useMediaQuery, useTheme } from "@mui/material";
import { Helmet } from "react-helmet";
import { useRef, useState, useEffect } from "react";
import NavBarRetake from "./NavbarRetake";
import NavBarDesktop from "scenes/bitewise/navbar/NavBarDesktop";
import { NotAvailable } from "scenes/bitewise/scanPage";
import BottomRetake from "./BottomRetake";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import "./../index.css";
import ExampleImage from "../assets/exampleStep1.png";
import { useSelector } from "react-redux";
import { getNameStep } from "scenes/bitewise/userScan";
import FlexBetweenVertical from "./FlexBetweenVertical";

const ImageCropRotate = ({ setReadImage }) => {
  const theme = useTheme();
  const isNonMobile = useMediaQuery("(min-width:1000px)");
  const isSmallMobile = useMediaQuery("(max-width:376px)");
  const backgroundColor = theme.palette.background.alt;
  const { step, image } = useSelector((state) => state.userScan);
  const [imageChange, setImageChange] = useState(
    image[getNameStep(step)] || ExampleImage
  );
  const [openCrop, setOpenCrop] = useState(false);
  const cropperRef = useRef(null);
  const [loading, setLoading] = useState(false);

  const handleClick = () => {
    const cropper = cropperRef?.current?.cropper;
    if (cropper) {
      const img = cropper.getCroppedCanvas().toDataURL();
      setImageChange(img);
      sessionStorage.setItem(getNameStep(step), img);
    }
  };

  const rotate = () => {
    const cropper = cropperRef?.current?.cropper;
    if (cropper) {
      cropper.rotate(90);
    }
  };

  useEffect(() => {
    const cropper = cropperRef?.current?.cropper;
    if (cropper) {
      cropper.replace(image);
    }
  }, [image]);

  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Helmet title={`User-scan`} />
      {isNonMobile ? (
        <NavBarDesktop />
      ) : (
        <NavBarRetake setImageSuccess={setReadImage} />
      )}
      <Box
        width="100%"
        display={isNonMobile ? "flex" : "block"}
        justifyContent="space-between"
        sx={{
          // flex: 1,
          display: "flex",
          flexDirection: "column",
          position: "relative",
          height: "100%",
        }}
      >
        <Box flexBasis={isNonMobile ? "26%" : undefined} />
        <Box
          flexBasis={isNonMobile ? "30%" : undefined}
          width={isNonMobile ? "30%" : undefined}
          minHeight={isNonMobile ? "100vh" : undefined}
          backgroundColor={backgroundColor}
          sx={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: isSmallMobile ? "flex-start" : "center",
            maxHeight: "100%",
            overflowY: "clip",
          }}
        >
          {isNonMobile ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                width: "100%",
                bgcolor: "#EBEBF0",
              }}
            >
              {NotAvailable()}
            </Box>
          ) : (
            <FlexBetweenVertical
              justifyContent={"space-between"}
              sx={{
                flex: 1,
                height: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  padding: "1rem",
                  justifyContent: "center",
                  height: "100%",
                }}
              >
                {!openCrop ? (
                  <img
                    src={imageChange}
                    alt="crop img"
                    style={{
                      width: "100%",
                      height: "100%",
                    }}
                  />
                ) : (
                  <Cropper
                    src={imageChange}
                    style={{ height: "100%", width: "100%" }}
                    initialAspectRatio={4 / 3}
                    guides={false}
                    ready={() => {
                      setLoading(false);
                    }}
                    ref={cropperRef}
                  />
                )}
              </Box>
              <Box height={82}> </Box>
            </FlexBetweenVertical>
          )}
          <BottomRetake
            onClickRorate={() => rotate()}
            handleClick={() => handleClick()}
            openCrop={(value) => {
              setOpenCrop(value);
            }}
          />
        </Box>
        {isNonMobile && <Box flexBasis="26%" />}
      </Box>
    </Box>
  );
};

export default ImageCropRotate;
