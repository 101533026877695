import { Box, Typography } from "@mui/material";
import { ReactComponent as QuestionIcon } from "../assets/Question-circle.svg";
import React, { useState } from "react";
import DialogWidget from "scenes/widgets/DialogWidget";
import NutritionFactGuidance from "./NutritionFactGuidance";
import { useSelector } from "react-redux";
import AdditiveGuidance from "./AdditiveGuidance";

const ProductFontCard = ({
  title,
  description,
  readImageSuccessfull,
  extraInf = {},
}) => {
  const { step } = useSelector((state) => state.userScan);
  const [openGuidline, setOpenGuidline] = useState(false);

  const handleClose = () => {
    setOpenGuidline(false);
  };
  return (
    <Box
      sx={{
        display: "flex",
        gap: "6px",
        flexDirection: "column",
        width: "217px",
      }}
    >
      <Typography
        variant="h3"
        sx={{
          fontWeight: "500",
          lineHeight: "30px",
        }}
      >
        {title}
      </Typography>
      <Typography
        sx={{
          fontWeight: "400",
          lineHeight: "17.5px",
          color: "#7D8899",
          fontSize: "14px",
        }}
      >
        {description}
      </Typography>
      {Object.keys(extraInf).length > 0 && (
        <Typography
          sx={{
            fontWeight: "400",
            lineHeight: "17.5px",
            color: "#FF751E",
            fontSize: "14px",
            display: "flex",
            alignItems: "center",
            gap: "6px",
          }}
          onClick={() => setOpenGuidline(true)}
        >
          {readImageSuccessfull ? extraInf.review : extraInf.example}
          <QuestionIcon />
        </Typography>
      )}
      {step === 2 && (
        <DialogWidget
          open={openGuidline}
          onClose={() => handleClose()}
          dialogContent={
            <NutritionFactGuidance onClose={() => handleClose()} />
          }
        />
      )}
      {step === 3 && (
        <DialogWidget
          open={openGuidline}
          onClose={() => handleClose()}
          dialogContent={<AdditiveGuidance onClose={() => handleClose()} />}
        />
      )}
    </Box>
  );
};

export default ProductFontCard;
