import { Box, IconButton, Typography } from "@mui/material";
import { ReactComponent as ArrowRight } from "../assets/Arrow-right.svg";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeData, changeNextStep } from "features/useScan/userScanSlice";
import SkipStep from "./SkipStep";

export const getDataUpdate = (step, value) => {
  switch (step) {
    case 1:
      return {
        name: "dataStep1",
        value: value,
      };
    case 2:
      return {
        name: "dataStep2",
        value: value,
      };
    case 3:
      return {
        name: "dataStep3",
        value: value,
      };
    default:
      return {};
  }
};

function areAmountsNotEmpty(data) {
  for (const key in data) {
    if (Object.hasOwnProperty.call(data, key)) {
      const obj = data[key];
      if (obj.amount === "" || obj.amount === undefined) {
        return false;
      }
    }
  }
  return true;
}

const ButtonSubmitContinue = ({ value, handleResetReadImage }) => {
  const { step } = useSelector((state) => state.userScan);
  const dispatch = useDispatch();

  const handleNextStep = () => {
    dispatch(changeData(getDataUpdate(step, value)));
    dispatch(changeNextStep());
    handleResetReadImage();
  };

  const isDataStep1Empty = (step, value) => {
    switch (step) {
      case 1:
        return value?.productBrand && value?.productName ? false : true;
      case 2:
        return !areAmountsNotEmpty(value);
      case 3:
        return false;
      default:
        return true;
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: "white",
        position: "fixed",
        width: "100%",
        bottom: 0,
        left: 0,
        borderTop: "1px solid #EBEBF0",
        padding: "16px 24px 16px 24px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "20px",
      }}
    >
      <IconButton
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "8px",
          justifyContent: "center",
          backgroundColor: isDataStep1Empty(step, value)
            ? "grey !important"
            : "#FF751E",
          padding: "12px 16px 12px 16px",
          borderRadius: "16px",
          width: "100%",
        }}
        onClick={() => handleNextStep()}
        disabled={isDataStep1Empty(step, value)}
      >
        <Typography
          sx={{
            fontWeight: 700,
            color: "white",
            lineHeight: "20px",
            letterSpacing: "0.1px",
            textAlign: "center",
          }}
        >
          All correct. Continue
        </Typography>
        <ArrowRight />
      </IconButton>
      <SkipStep handleResetReadImage={handleResetReadImage} />
    </Box>
  );
};

export default ButtonSubmitContinue;
