import { Box, Typography, useTheme } from "@mui/material";
import React from "react";
import NutririonImage from "./../assets/nutrition_guidancepng.png";
import NutririonImage2 from "./../assets/nutrition_gui3.png";
import ExampleGuidance from "./../assets/examleGuidance.png";
import FlexBetweenVertical from "./FlexBetweenVertical";
import FlexBetween from "./FlexBetween";
import HeaderBottomSheet from "scenes/widgets/HeaderBottomSheet";
import HeaderGuidance from "./HeaderGuidance";

const NutritionFactGuidance = ({ onClose }) => {
  const { palette } = useTheme();
  const backgrountWhite = palette.background.alt;

  const nutritionValues = [
    "Calories",
    "Sugar",
    "Fiber",
    "Saturated fat",
    "Sodium",
  ];
  const cases = ["Case 1", "Case 2"];

  const renderListItems = (items) => {
    return items.map((item, index) => (
      <li key={index} style={{ color: "#32363D" }}>
        {item}
      </li>
    ));
  };
  return (
    <Box height="100%" backgroundColor={backgrountWhite}>
      <HeaderBottomSheet
        name={"Nutrition Fact guidance"}
        icon={""}
        close={onClose}
      />
      <FlexBetweenVertical
        sx={{
          gap: "30px",
          justifyContent: "flex-start",
          alignItems: "flex-start",
        }}
      >
        <FlexBetweenVertical
          sx={{
            gap: "12px",
            justifyContent: "flex-start",
            alignItems: "flex-start",
          }}
        >
          <HeaderGuidance content={"Where can find"} />
          <Typography fontSize={"14px"} color={"#32363D"}>
            Nutrition fact table is usually in the back or on the side of the
            package.
          </Typography>
          <FlexBetween
            sx={{
              width: "100%",
              gap: "12px",
              flex: "1 1",
              alignItems: "center",
            }}
          >
            <img
              src={NutririonImage}
              alt="nutrition guidance"
              height={130}
              width={"50%"}
            />
            <img
              src={NutririonImage2}
              alt="nutrition guidance"
              height={130}
              width={"50%"}
            />
          </FlexBetween>
          <Typography fontSize={"14px"} color={"#32363D"}>
            If you cannot find ...
          </Typography>
        </FlexBetweenVertical>
        <FlexBetweenVertical
          sx={{
            gap: "12px",
            justifyContent: "flex-start",
            alignItems: "flex-start",
          }}
        >
          <HeaderGuidance
            content={"the quantity the nutrition fact is calculated on"}
          />
          <Typography fontSize={"14px"} color={"#32363D"}>
            brief explain
          </Typography>
          <FlexBetween
            sx={{
              width: "100%",
              gap: "12px",
              flex: "1 1",
              alignItems: "center",
            }}
          >
            <FlexBetweenVertical
              sx={{
                width: "50%",
              }}
            >
              <img src={ExampleGuidance} alt="example i" width={"100%"} />
              <Typography>example 1</Typography>
            </FlexBetweenVertical>
            <FlexBetweenVertical
              sx={{
                width: "50%",
              }}
            >
              <img src={ExampleGuidance} alt="example i" width={"100%"} />
              <Typography>example 1</Typography>
            </FlexBetweenVertical>
          </FlexBetween>
          <Typography fontSize={"14px"} color={"#32363D"}>
            Please make sure to enter the values according to the amount the
            nutrition is calculated on bla bla bla ...
          </Typography>
        </FlexBetweenVertical>
        <FlexBetweenVertical
          sx={{
            gap: "12px",
            justifyContent: "flex-start",
            alignItems: "flex-start",
          }}
        >
          <HeaderGuidance content={"nutrition values"} />
          <ul
            style={{
              fontSize: "14px",
              color: "#32363D",
              margin: 0,
              paddingLeft: "1rem",
            }}
          >
            {renderListItems(nutritionValues)}
          </ul>
          <ul
            style={{
              fontSize: "14px",
              color: "#32363D",
              margin: 0,
              paddingLeft: "1rem",
            }}
          >
            {renderListItems(cases)}
          </ul>
        </FlexBetweenVertical>
        <Typography>
          If you are not sure how to read and review the values, just leave it
          to us!
        </Typography>
      </FlexBetweenVertical>
    </Box>
  );
};

export default NutritionFactGuidance;
