import React from "react";
import { IconButton, useTheme, useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import FlexBetween from "components/FlexBetween";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LoadingSteps from "./LoadingStep";

const NavBarUserScan = ({ appBarBackgroundColor = "#ffffff", step = 1 }) => {
  const navigate = useNavigate();
  const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");
  const { palette } = useTheme();
  const alt = palette.background.alt;
  return (
    <FlexBetween
      width="100%"
      padding={isNonMobileScreens ? "1rem 1.25rem" : "1rem 1.25rem"}
      backgroundColor={isNonMobileScreens ? alt : appBarBackgroundColor}
      position={"relative"}
      zIndex={10}
    >
      {!isNonMobileScreens && (
        <FlexBetween
          gap="1rem"
          sx={{ justifyContent: "flex-between", width: "100%" }}
        >
          <IconButton
            onClick={() => navigate(-1)}
            sx={{
              padding: 0,
            }}
          >
            <ArrowBackIcon
              style={{ color: "#3D3C3D", width: "1.5rem", height: "1.5rem" }}
            />
          </IconButton>
          <LoadingSteps totalSteps={3} currentStep={step <= 3 ? step : 3} />
        </FlexBetween>
      )}
    </FlexBetween>
  );
};

export default NavBarUserScan;
