import { Box, Typography } from "@mui/material";
import React from "react";
import FlexBetweenVertical from "./FlexBetweenVertical";
import FlexBetween from "./FlexBetween";

const ProductUnderReview = () => {
  return (
    <FlexBetween
      gap={"6px"}
      sx={{
        padding: "1.5rem",
        backgroundColor: "white",
        boxShadow: "0px 0px 16px 0px #00000033 ",
        position: "fixed",
        bottom: 0,
        left: 0,
        width: "100%",
        zIndex: 50,
        justifyContent: "flex-start",
        alignItems : 'self-start'
      }}
    >
      <Typography
        sx={{
          fontSize: "32px",
        }}
      >
        🕵️‍♂️
      </Typography>
      <FlexBetweenVertical
        gap={"6px"}
        sx={{
          justifyContent: "flex-start",
          alignItems: "flex-start",
        }}
      >
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: 600,
            lineHeight: "20px",
            textAlign: "left",
            color: "#191B1F",
          }}
        >
          Product under review
        </Typography>
        <Typography
          sx={{
            color: "#32363D",
            fontSize: "13px",
            lineHeight: "16.25px",
            textAlign: "left",
            fontWeight: 400,
          }}
        >
          The result for this product is not yet official. When the review is
          done, the product will be published, then you can search or scan it.
        </Typography>
      </FlexBetweenVertical>
    </FlexBetween>
  );
};

export default ProductUnderReview;
