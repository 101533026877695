import React from "react";

const LabelInput = ({ name }) => {
  return (
    <label
      style={{
        color: "#7D8899",
        textTransform: "uppercase",
        letterSpacing: "10%",
        fontWeight: "400",
        fontSize: "12px",
        lineHeight: "15px",
      }}
    >
      {name}
    </label>
  );
};

export default LabelInput;
