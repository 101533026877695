import React from "react";
import {
  IconButton,
  useTheme,
  useMediaQuery,
  Box,
  Typography,
} from "@mui/material";
import FlexBetween from "components/FlexBetween";
import CloseIcon from "@mui/icons-material/Close";
import ReplayIcon from "@mui/icons-material/Replay";
import { useDispatch, useSelector } from "react-redux";
import { changeOpenEditImage } from "features/useScan/userScanSlice";
import { getNameStep } from "scenes/bitewise/userScan";

const NavBarRetake = ({
  appBarBackgroundColor = "#ffffff",
  setImageSuccess,
}) => {
  const { step } = useSelector((state) => state.userScan);
  const dispatch = useDispatch();
  const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");
  const { palette } = useTheme();
  const alt = palette.background.alt;
  return (
    <FlexBetween
      width="100%"
      padding={isNonMobileScreens ? "1rem 1.25rem" : "1rem 1.25rem"}
      backgroundColor={isNonMobileScreens ? alt : appBarBackgroundColor}
      position={"relative"}
      zIndex={10}
      sx={{
        borderBottom: "1px solid #EBEBF0",
      }}
    >
      {!isNonMobileScreens && (
        <FlexBetween
          gap="1rem"
          sx={{ justifyContent: "flex-between", width: "100%" }}
        >
          <IconButton
            onClick={() => dispatch(changeOpenEditImage(false))}
            sx={{
              padding: 0,
            }}
          >
            <CloseIcon
              style={{ color: "#3D3C3D", width: "1.5rem", height: "1.5rem" }}
            />
          </IconButton>
          <Box>
            <IconButton
              sx={{
                padding: "2px",
              }}
              onClick={() => {
                sessionStorage.removeItem(getNameStep(step));
                dispatch(changeOpenEditImage(false));
                setImageSuccess();
              }}
            >
              <ReplayIcon
                style={{ color: "#3D3C3D", width: "1.5rem", height: "1.5rem" }}
              />
              <Typography
                sx={{
                  color: "#3D3C3D",
                  fontSize: "0.75rem",
                  fontWeight: "700",
                  letterSpacing: "0.1px",
                  lineHeight: "17.5px",
                }}
              >
                Re-take
              </Typography>
            </IconButton>
          </Box>
        </FlexBetween>
      )}
    </FlexBetween>
  );
};

export default NavBarRetake;
